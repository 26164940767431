<template>
    <div id="Admin_header" class="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">            
        <div class="container-xxl d-flex flex-grow-1 flex-stack">
            <div class="d-flex align-items-center me-5">
                <router-link to="/" >
                    <img alt="Logo" :src="COMPANY_LOGO" class="h-40px h-lg-40px" />
                </router-link>
            </div>
            <div class="d-flex align-items-center flex-shrink-0 position-relative ">
                <div id="kt_header_search" class="d-flex align-items-center w-lg-250px" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-search-responsive="lg" data-kt-menu-trigger="auto" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-end">
                    <div data-kt-search-element="toggle" class="d-flex d-lg-none align-items-center">
                        <div class="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                            <span class="svg-icon svg-icon-1 svg-icon-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                    </div>
                </div>
      
             <HeaderNotifications ></HeaderNotifications>
             <div  @click="showheaderManu('notificationSection')" class="d-flex align-items-center ms-3 ms-lg-2">               
                <div  class="btn btn-icon btn-active-light btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px position-relative menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                    <span class="badge badge-light bg-danger"  v-if="unRead > 0" style="position: absolute;top: -13px;padding: 6px;color: white;left: 21px;">
                     {{unRead}}</span>
                    <span class="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3"
                  d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                  fill="black"></path>
                <path
                  d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                  fill="black"></path>
              </svg>
            </span>
         
                </div>
                <!--begin::Menu-->
             <div id="notificationSection" class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
                    <div class="container topnotificationbar">
                        <div class="row heading">
                            <div class="col-md-12">
                                <span class="notification_title fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Notifications</span>
                            </div>
                        </div>
                    </div>
                    <ul class="head_notification_alerts_list">
                        <template v-if="notification.length>0">
                        <li v-for="(list,index) in notification" :key="index">
                            <a hre="#" @click="goTochat(list)" class="active cursor-pointer">
                                <div class="d-flex align-items-center">
                                    <div class="symbol symbol-50px me-5">
                                        <img :src="Path+list.Company_logo" class="" alt="">
                                    </div>
                                    <div class="flex-grow-1">
                                        <span ><b>{{list.Account_type_name?list.Account_type_name:list.account_name_frm}}</b></span>
                                        <span class="text-muted d-block fw-bold"><b>{{list.Company_name?list.Company_name:list.regd_name_frm}} send you a message</b></span>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                    </div>
                                </div>
                            </a>
                        </li>
                    </template>
                    <li v-else class="no_noti">
                         No Notifications Available
                    </li>
                    </ul>
                </div>
               
            </div>
              
                <div class="d-flex align-items-center ms-3 ms-lg-4" id="kt_header_user_menu_toggle">
                    <div @click="showheaderManu('headermanu')"  class="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                        <!--begin::Svg Icon | path: icons/duotune/communication/com013.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="black" />
                                <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black" />
                            </svg>
                        </span>
                    </div>
                    <div id="headermanu" class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                            <div class="menu-content d-flex align-items-center px-3">
                                <!--begin::Avatar-->
                              
                                <div class="symbol symbol-100px me-5">
                                    <img :src="Path + PS.profileDetail[0]?.user_image" style="width: 50px;height: 50px;border-radius: 50%;"/>
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                    <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="black" />
                                    <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black" />
                                </svg>                                 -->
                            </div>
                                <!--end::Avatar-->
                                <!--begin::Username-->
                                <div class="d-flex flex-column">
                                    <div class="fw-bolder d-flex align-items-center fs-5"> {{  PS.profileDetail[0]?.User_fullname }} </div>
                                    <div class="d-flex align-items-center"> {{  PS.profileDetail[0]?.User_email }} </div>
                                    <!--													<a href="#" class="fw-bold text-muted text-hover-primary fs-7">max@kt.com</a>-->
                                </div>
                                <!--end::Username-->
                            </div>
                        </div>
                        <div class="separator my-2"></div>
                        <div class="menu-item px-5">
                            <router-link to="/my-profile" class="menu-link px-5">My Profile</router-link>
                        </div>
                        <div class="separator my-2"></div>
                        <div class="menu-item px-5">
                            <a @click="logout" class="menu-link px-5">{{$t('logout')}}</a>
                        </div>
                        <!--end::Menu item-->
                    </div>
                </div>
                <div @click="toggleButton()" class="d-md-none btn btn-icon btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px ms-3" id="kt_header_menu_toggle">
                   
                    <span class="svg-icon svg-icon-2" >
                        <svg v-if="!show" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                            <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                        </svg>
                        <span v-else style="font-size: 30px;color: black;">&times;</span>
                    </span>
                  
                </div>
                <!-- <div class="d-flex align-items-center ms-3 ms-lg-4 " style="position: relative;" id="language"  v-if="Nlang != 'en'"> -->
          <!-- <div @click="showheaderManu('lange')"  class="
          changeLanguge
              btn
              btn-icon
              btn-color-gray-700
              btn-active-color-primary
              btn-outline
              btn-outline-secondary
              w-30px
              h-30px
              w-lg-40px
              h-lg-40px
            " data-kt-menu-trigger="hover" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
            <span class="svg-icon svg-icon-1">
               Change Language
            </span>  
          </div> -->
<!--         
          <div class="
              language
              menu
              menu-sub
              menu-sub-dropdown
              menu-column
              menu-rounded
              menu-gray-800
              menu-state-bg
              menu-state-primary
              fw-bold
              py-4
              fs-6
              w-275px
            " data-kt-menu="true" id="lange">
           
           
            <div class="menu-item px-5">
              <button style="width: 100%; z-index: 1 !important;background: transparent !important;" @click="changeLangunge()" :class="lang == 'en'?'activeL':''" class="bg-transparent border-0 py-0 menu-link px-5">
                English
              </button>
            </div>
            <div class="separator my-2"></div>
            <div class="menu-item px-5"  v-if="Nlang != 'ar'">
              <button style="width: 100%; z-index: 1 !important;background: transparent !important;"  @click="clearLangunge()" :class="lang != 'en'?'activeL':''" class="bg-transparent border-0 py-0 menu-link px-5">
                {{languageNames.of(Nlang)}}
              </button>
            </div>
            <div class="menu-item px-5"  v-if="Nlang == 'ar'">
              <button style="width: 100%; z-index: 1 !important;background: transparent !important;"  @click="ChangeArabicLangunge()" :class="lang != 'en'?'activeL':''" class="bg-transparent border-0 py-0 menu-link px-5">
               Arabic
              </button>
            </div>
            
          </div>  -->
        
        </div>
                <!--end::Heaeder menu toggle-->

            </div>
            <!--end::Topbar-->
        </div>
<!-- </div> -->
</template>

<script setup>
import HeaderNotifications from './HeaderNotifications.vue'
/* eslint-disable */
import { computed, onMounted,ref, watch } from "vue";
import { useRouter } from "vue-router";
import { profileStore } from '@/store/profile';
import {chat} from '@/store/chat'
import {toggleSideBar} from '@/composables/toggle.js'
import {WebScoketConnection} from '@/composables/WebScoket'
const {initilizeWebScoket}  = WebScoketConnection()
import {messages} from '@/composables/ReceiveMassage'
import {storeToRefs} from 'pinia'
const {notifications_data}  = messages()
const store = chat()
const {chat_notification} = storeToRefs(store)
const PS =profileStore()
const COMPANY_LOGO = computed(() => `/${process.env.VUE_APP_COMPANY_LOGO}` ); 
const Path = process.env.VUE_APP_Images
const unRead = ref(0)
const router = useRouter()
const notification = ref([])
// const user = JSON.parse(localStorage.getItem('user'))
const lang = ref('')
// const Nlang = ref(navigator.language.substring(0,2)=='en'?'ar':navigator.language.substring(0,2))
const logout = () => {
    router.push("/login")
    localStorage.clear();
}
const {toggleButton,show} = toggleSideBar()
const socket_notification = computed(()=>{
    unRead.value=0
        for (let x of notifications_data.value){
            unRead.value+=parseInt(x.unread);
        }
        return notifications_data.value
      })

watch(socket_notification,()=>{
    notification.value=socket_notification.value
})

watch(chat_notification,()=>{
    unRead.value=0
        for (let x of chat_notification.value){
            unRead.value+=parseInt(x.unread);
        }
    notification.value=chat_notification.value
})



document.onclick = function(){
   let ele =  document.getElementsByClassName('showheadermanu')
   for(let x of ele)
   {
    x.classList.remove('showheadermanu')
   }
}

const showheaderManu =(e)=>{
setTimeout(()=>{
let ele = document.getElementById(e)
ele.classList.add('showheadermanu')
},100) 
}

const goTochat =(evt)=>{
let d = JSON.stringify(evt)
  window.location.href=`/chat/${d}`
}


// const changeLangunge =()=>{
//   localStorage.setItem('lang','en')
//   location.reload();
//   // setCookie('googtrans', `/en/en`,1);
// }

// const ChangeArabicLangunge =()=>{
//   localStorage.setItem('lang','ar')
//   location.reload();
// }

// const  clearLangunge=()=>{
//   // setCookie('googtrans', `/en/ur`,1);
//   localStorage.removeItem('lang')
//   location.reload()
// }

// function setCookie(cname, cvalue, exdays) {
//           const d = new Date();
//           d.setTime(d.getTime() + (exdays*24*60*60*1000));
//           let expires = "expires="+ d.toUTCString();
//           document.cookie = 'googtrans' +'=; Path=/;  Domain=' + '.glasshub.ai' +  '; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure' 
//           document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"; 
//         }

//    async  function  googleTranslateElementInit(lang) {
//             setCookie('googtrans', `/en/${lang}`,1);
          
//         //     new google.translate.TranslateElement({
//         //         pageLanguage: 'en'
//         //    }, 'google_translate_element');
//         }
//      const languageNames = new Intl.DisplayNames(['en'], {
//   type: 'language'
// })




onMounted(()=>{
        store.getnotification()
        initilizeWebScoket()
    // Nlang.value=navigator.language.substring(0,2)
    // if(!localStorage.getItem('lang'))
    //       {
    //         lang.value = navigator.language.substring(0,2)
    //         googleTranslateElementInit(lang.value)
    //       }else{
        
    //         lang.value  = localStorage.getItem('lang')  
    //         googleTranslateElementInit(lang.value) 
    //       }
          let user =  JSON.parse(localStorage.getItem('user'))
          PS.getProfile(user.User.User_id)
})
</script>

<style scoped>
.head_notification_alerts_list{
    max-height: 400px;
    overflow-y: auto;
}
    .showheadermanu{
  position:absolute !important;
  top:50px !important; 
}
.no_noti{
    padding: 10px;
    text-align: center;
    color: #a0a3a6;
}
    .changeLanguge{
    width: fit-content !important;
    font-size: 11px;
    color: #5241a1;
    padding: 2px;
    border: 1px dashed #E4E6EF;
  }
.language{
    position: absolute;
    top: 44px;
    right:0px
  }
.lang{
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.lang>span{
    cursor: pointer;
    font-weight: bold;
  }
  .activeL{
    color: #5241a1;
    position: relative;
  
}
 .activeL::before{
  position: absolute;
    left: -12px;
    top: 63%;
    height: 34%;
    width: 2px;
    background-color: #5241a1;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}
.activeL::after {
   position: absolute;
    left: -12px;
    bottom: 0;
    height: 2px;
    width: 17px;
    background-color: #336699;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}
.showheadermanu{
  display: block;
  position: absolute;
  top:50px;
  right: 5px;
}
.container-xxl{
  @media (min-width: 1800px){
    max-width: 1720px;
  }
}
</style>